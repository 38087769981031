




































































































































































































































































































































import Vue from "vue";
interface Data {
    fill: string,
    classes: string[],
    range: string[],
}

export default Vue.extend({
    name: "logo",
    props: {
        mode: {
            required: false,
            type: String,
            default: 'static',
            validator(value: 'static'): boolean {
                const allowed = ['static', 'pulsing', 'rainbow', 'thinking', 'flickering'];
                return allowed.includes(value);
            }
        },
        defaultFill: {
            required: false,
            type: String,
            default: '#000000',
        },
        animateText: {
            required: false,
            type: Boolean,
            default: true,
        },
    },
    data(): Data {
        return {
            fill: this.defaultFill,
            classes: ['pulsing'],
            range: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A'],
        }
    },
    computed: {
        svg_refs(): string[] {
            let refs = [];
            for (let i = 1; i < 55; i++) refs.push(i.toString());
            if (this.animateText) refs = refs.concat(['g','o-1','o-2','m-1','c','o-3','o-4','m-2']);
            return refs;
        },
    },
    mounted(): void {
        switch (this.mode) {
            case 'pulsing':
                this.pulsate();
                break;
            case 'rainbow':
                this.rainbow();
                break;
            case 'thinking':
                this.think();
                break;
            case 'flickering':
                this.flicker();
                break;
        }
    },
    methods: {
        pulsate(): void {
            if (this.animateText) {
                this.replaceClass('logo', 'pulsing')
            } else {
                this.replaceClass('left', 'pulsing');
                this.replaceClass('right', 'pulsing');
            }
        },

        rainbow(): void {
            const text = this.$refs['text'] as any as Element;
            if (!this.animateText) {
                text.setAttribute('fill', this.defaultFill);
            } else {
                text.removeAttribute('fill');
            }
            setInterval(() => {
                this.fill = this.pickColor();
            }, 4000);
        },

        think(): void {
            setInterval(() => {
                const el: SVGElement = this.selectRandomElement();
                el.setAttribute('fill', this.pickColor())
            }, 30)
        },

        flicker(): void {
            setInterval(() => {
                const el: SVGElement = this.selectRandomElement();
                el.classList.add('flickering');
            }, 30);

            setInterval(() => {
                const el: SVGElement = this.selectRandomElement();
                el.classList.remove('flickering');
            }, 60);
        },

        selectRandomElement(): SVGElement {
            const random = this.svg_refs[Math.floor(Math.random() * this.svg_refs.length)]
            return this.$refs[random] as any as SVGElement;
        },

        replaceClass(ref: string, add: string) {
            const el = this.$refs[ref] as any as SVGElement;
            const remove = this.classes.filter(c => c !== add);
            el.classList.remove(...remove);
            el.classList.add(add);
        },

        pickColor(): string {
            let color: string = '#';
            for (let i = 0; i < 6; i++) color += this.range[Math.floor(Math.random() * this.range.length)];
            return color;
        },
    }
});
