






import Vue from "vue";
import ComingSoon from "~/components/coming-soon.vue"

export default Vue.extend({
    name: 'App',
    components: {
        ComingSoon
    }
});
