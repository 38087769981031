











import Vue from "vue";
import Logo from "~/components/utils/logo.vue"

export default Vue.extend({
    name: "coming-soon",
    components: {
        Logo,
    },
});
